<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}App第三方信息共享清单</h3>
      <section>
        <p>
          <b
            ><u>
              注意：为了持续向您提供更多优质服务、持续优化我们提供服务的稳定性、服务质量或者升级相关功能，我们可能会调整我们的第三方信息共享清单。我们会严格遵守相关法律法规与监管要求，在共享前评估第三方处理个人信息的合法性、正当性、必要性，以保护个人信息安全</u
            ></b
          >
        </p>
      </section>
      <section>
        <div class="table">
          <div class="t-head">
            <ul>
              <li v-for="(item, index) in option.column" :key="index">{{ item.label }}</li>
            </ul>
          </div>
          <div class="t-body" v-for="(item, index) in tableData" :key="index">
            <ul>
              <li v-for="(context, i) in option.column" :key="i">
                <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                <span v-else class="align-start"
                  ><a :href="item[context.prop]" v-if="typeof item[context.prop] === 'string'">{{
                    item[context.prop]
                  }}</a>
                  <a :href="link" v-for="link in item[context.prop]" v-else>{{ link }} <br /></a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        tableData: [
          {
            Receive: '杭州小满信息科技有限公司',
            Contact: 'guyu@hixiaoman.com',
            Treatment: '收集、存储、使用',
            Uses: 'SaaS营销工具（内容）',
            Type: '用户ID',
          },
          {
            Receive: '腾讯音乐娱乐（深圳）有限公司',
            Contact: '+86 (755) 8601-3388',
            Treatment: '收集、存储、使用',
            Uses: '播放版权音乐',
            Type: '设备信息，用户行为数据',
          },
          {
            Receive: '林州薪云信息科技有限公司',
            Contact: '4008-381-081',
            Treatment: '收集、存储、使用',
            Uses: '现金提现',
            Type: '手机号、实名认证信息（提现到一定金额）、微信open id、支付宝账号',
          },
          {
            Receive: '数美天下（北京）科技有限公司',
            Contact: '400-610-3866',
            Treatment: '收集、存储、使用',
            Uses: '视频审核',
            Type: '视频流',
          },
          {
            Receive: '北京火山引擎科技有限公司',
            Contact: '400-850-0030',
            Treatment: '收集、存储、使用',
            Uses: '音视频字幕识别',
            Type: '用户内容数据',
          },
          {
            Receive: '深圳市和讯华谷信息技术有限公司',
            Contact: 'support@jiguang.cn',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '科大讯飞股份有限公司',
            Contact: '0551-65331511',
            Treatment: '收集、存储、使用',
            Uses: '彩铃合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '深圳前海新之江信息技术有限公司',
            Contact: 'privacy@pinduoduo.com',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '上海美数广告有限公司',
            Contact: '010-85907587',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '上海佳投互联网技术集团有限公司',
            Contact: 'adx@jiaads.com',
            Treatment: '',
            Uses: '',
            Type: '',
          },
          {
            Receive: '天津快友世纪科技有限公司',
            Contact: 'service@adview.com',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '广州幂动科技有限公司',
            Contact: 'bussiness@midongtech.cn',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '北京美数信息科技有限公司',
            Contact: '010-59007186',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
        ],
        option: {
          column: [
            {
              label: '数据接收方',
              prop: 'Receive',
            },
            {
              label: '联系方式',
              prop: 'Contact',
            },
            {
              label: '处理方式',
              prop: 'Treatment',
            },
            {
              label: '用途',
              prop: 'Uses',
            },
            {
              label: '共享个人信息类型',
              prop: 'Type',
            },
          ],
        },
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', moreOpts: false }],
          ['kuaiyintingge', { appName: '快音听歌版', moreOpts: false }],
          ['kuaiyinjisu', { appName: '快音极速版', moreOpts: false }],
          ['kuaiyinyue', { appName: '快音悦', moreOpts: false, moreMoreOpts: true }],
          ['kuaiyinfm', { appName: '快音FM', moreOpts: true }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyin';
      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName}App第三方信息共享清单`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  .align-start {
    text-align: start;
  }
  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
  }
</style>
